import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [4],
		"/(auth)/cancellation": [~18,[3]],
		"/(app)/dashboard": [~5,[2]],
		"/(app)/invite": [~6,[2]],
		"/(app)/meetings": [~7,[2]],
		"/(app)/meetings/details/[meetingId]": [~9,[2]],
		"/(app)/meetings/[meetingId]": [~8],
		"/(app)/notifications": [~10,[2]],
		"/(app)/opportunities": [~11,[2]],
		"/(app)/opportunities/[opportunitySlug]": [~12,[2]],
		"/privacy-policy/en": [31],
		"/privacy-policy/it": [32],
		"/(app)/profile/[profileSlug]": [~13,[2]],
		"/(app)/profile/[profileSlug]/account": [~14,[2]],
		"/(app)/profile/[profileSlug]/account/email-updated": [15,[2]],
		"/(app)/profile/[profileSlug]/feedback": [~16,[2]],
		"/(auth)/registration/hub": [~21,[3]],
		"/(auth)/registration/(interests)/interests": [~19,[3]],
		"/(auth)/registration/intro": [~22,[3]],
		"/(auth)/registration/name": [~23,[3]],
		"/(auth)/registration/objectives": [~24,[3]],
		"/(auth)/registration/(interests)/personal-interests": [~20,[3]],
		"/(auth)/registration/prospects": [~25,[3]],
		"/(auth)/registration/social": [~26,[3]],
		"/(auth)/registration/welcome": [~27,[3]],
		"/(app)/saved-profiles": [~17,[2]],
		"/(auth)/sign-in": [~28,[3]],
		"/(auth)/sign-out": [29,[3]],
		"/(auth)/verify": [~30,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';